<template>
	<div :style="{height:height}" class="imgTextBox scrollbar">
		<!-- 县级页面特产资源、规划图特产资源 @mouseover="showBigImg(index, item.pictureUrl)" @mouseout="hideBigImg()"-->
		<div v-if="!type" class="dataItem" v-for="(item,index) in dataList" :key="index">
			<div class="img">
				<lineBorderBox />
				<img :src="item.pictureUrl" :ref="'ref_tczy_'+index" >
			</div>
			<el-tooltip effect="dark" :content="item.detailContent" placement="right" style="margin: 0 0.04rem 0 0.08rem;width: calc(100% - 1rem);">
				<div>
					<div style="display: flex;justify-content: space-between;align-items: center;">
						<div class="info_title">
							<div class="info_dot"><span></span></div>
							<span style="margin-left: 0.04rem;">{{ item.title }}</span>
						</div>
					</div>
					<p class="text">{{item.detailContent}}</p>
				</div>
			</el-tooltip>
		</div>
		<!-- 规划图村庄变迁 -->
		<div v-if="type =='specialtyResources'" class="dataItem" style="display: flex;flex-direction: column;cursor: pointer;"
			v-for="(item,index) in dataList" :key="index">
			<div class="year-box">
				<div class="line1"></div>
				<span>{{item.year}}</span>
				<div class="line2"></div>
			</div>
			<div style="display: flex;" class="listItem" v-for="(i,inx) in item.changeList" :key="inx"  @click="showTableInfo(i,index,inx)">
				<div class="left-line">
					<img src="../assets/new_img/c1.png" alt="">
					<img src="../assets/new_img/c2.png" alt="">
				</div>
				<div class="img" style="width: 0.56rem;height: 0.56rem;">
					<lineBorderBox />
					<img style="width: 90%;height: 90%;" src="../assets/new_img/vllage.png">
				</div>
				<div style="margin: 0 0.04rem 0 0.08rem;width: calc(100% - 1rem);">
					<div style="display: flex;justify-content: space-between;align-items: center;">
						<div class="info_title">
							<div class="info_dot" style="margin: 0;"><span></span></div>
							<span style="margin-left: 0.04rem;">{{ i.name }}</span>
						</div>
						<div style="width: 0.56rem;height: 0.2rem;cursor: pointer;">
							<img style="width: 100%;" src="../assets/new_img/d1.png" alt="">
						</div>
					</div>
					<p class="text" style="-webkit-line-clamp:2;">{{i.description}}</p>
				</div>
			</div>
		</div>
		<!-- 空间规划 -->
		<div v-if="type == 'spatialPlanning'" class="dataItem" v-for="(item,index) in dataList" :key="index" style="display: flex;flex-direction: column;cursor: pointer;">
			<div v-for="(i,inx) in item.changeList" class="listItem" :key="inx"  @click="showTableInfo(i,index,inx)">
				<div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
					<div class="img" style="margin-left: 0.04rem;width: 0.48rem;height: 0.48rem;">
						<lineBorderBox />
						<img style="width: 90%;height: 90%;" src="../assets/new_img/spatial.png">
					</div>
					<div style="margin: 0 0.04rem 0 0.08rem;width: calc(100% - 1rem);">
						<div style="display: flex;justify-content: space-between;align-items: center;">
							<div class="info_title">
								<div class="info_dot" style="margin: 0;"><span></span></div>
								<span style="margin-left: 0.04rem;">{{ i.name }}</span>
							</div>
						</div>
						<p class="text" style="-webkit-line-clamp:2;">{{i.description}}</p>
					</div>
					<div
						style="width: 0.56rem;height: 0.2rem;display: flex;align-items: center;height: 100%;margin-right: 0.08rem;cursor: pointer;">
						<img src="../assets/new_img/d1.png" style="width: 100%;" alt="">
					</div>
				</div>
			</div>
		</div>
		<!-- 历史沿革 -->
		<div v-if="type =='history'" class="dataItem" style="display: flex;flex-direction: column;cursor: pointer;"
			v-for="(item,index) in dataList" :key="index">
			<div class="year-box">
				<div class="line1"></div>
				<span>{{item.year}}</span>
				<div class="line2"></div>
			</div>
			<div style="display: flex;" class="listItem" v-for="(i,inx) in item.changeList" :key="inx"  @click="showTableInfo(i,index,inx)">
				<div class="left-line">
					<img src="../assets/new_img/c1.png" alt="">
					<img src="../assets/new_img/c2.png" alt="">
				</div>
				<div class="img" style="width: 0.56rem;height: 0.56rem;">
					<lineBorderBox />
					<img style="width: 120%;height: 120%;" src="../assets/new_img/lis.png">
				</div>
				<div style="margin: 0 0.04rem 0 0.08rem;width: calc(100% - 1rem);">
					<div style="display: flex;justify-content: space-between;align-items: center;">
						<div class="info_title">
							<div class="info_dot" style="margin: 0;"><span></span></div>
							<span style="margin-left: 0.04rem;">{{ i.name }}</span>
						</div>
						<div style="width: 0.56rem;height: 0.2rem;cursor: pointer;">
							<img style="width: 100%;" src="../assets/new_img/d1.png" alt="">
						</div>
					</div>
					<p class="text" style="-webkit-line-clamp:2;">{{i.description}}</p>
				</div>
			</div>
		</div>
		<img v-show="v_show_img" :style="'position:fixed;top:'+v_y+'px;left:'+v_x+'px'" :src="v_url">
	</div>
</template>

<script>
	import lineBorderBox from '../components/line-border-box.vue'
	export default {
		props: {
			type: String,
			dataList: [],
			height: String
		},
		components: {
			lineBorderBox
		},
		data: function(){
			return {
				v_show_img: false,
				v_url: '',
				v_x: 0,
				v_y: 0
			}
		},
		methods: {
			showTableInfo: function(item, index, inx) {
				this.$emit('showTableInfo', item, inx)
			},
			showBigImg: function(index, url){
				const _el = this.$refs['ref_tczy_'+index];
				console.log(index, url, _el);
				const _dom = _el[0];
				const _rect = _dom.getBoundingClientRect();
				console.log(_rect);
				// const rect = _dom.getBoundingClientRect();
				// console.log(rect);
				this.v_show_img = true;
				this.v_url = url;
				this.v_x = _rect.left + _dom.width + 10;// 
				this.v_y = _rect.top;
				//修正最后放大图片的位置，保证不出现遮挡
				const _maxHeight = document.body.clientHeight;
				const _imgRealHeight = _dom.naturalHeight;
				const _maxWidth = document.body.clientWidth;
				const _imgRealWidth = _dom.naturalWidth;
				if(_rect.top >= _maxHeight-_imgRealHeight){
					this.v_y = _maxHeight-_imgRealHeight-5;
				}
				if(_rect.left >= _maxWidth-_imgRealWidth-_rect.width){
					this.v_x = _rect.left-_imgRealWidth-5;
				}
				console.log(_dom.x, _dom.y, _dom.width, document.body.clientWidth,document.body.clientHeight);
			},
			hideBigImg: function(){
				this.v_show_img = false;
			}
		}
	}
</script>

<style lang="scss">
	.dataItem {
		display: flex;
		margin-bottom: 0.08rem;
		position: relative;
		color: #EBEFF5;

		.img {
			width: 1rem;
			height: 1rem;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;

			img {
				width: 0.9rem;
				height: 0.9rem;
				object-fit: cover;
				-o-object-fit: cover;
			}
		}

		.text {
			font-size: 0.12rem;
			font-weight: 400;
			color: #EBEFF5;
			line-height: 0.18rem;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
		}
	}

	.imgTextBox {
		width: 100%;
		overflow: auto;
	}

	.info_title {
		display: flex;
		align-items: center;

		.info_dot {
			width: 0.12rem;
			height: 0.12rem;
			margin: 0.1rem 0;
			border: 0.01rem solid #069999;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;

			span {
				display: block;
				width: 0.06rem;
				height: 0.06rem;
				box-shadow: 0 0 0.03rem 0.01rem #18F5F5;
				background-color: #18F5F5;
			}
		}

		font-size: 0.14rem;
		color: #00FFFF;
	}

	.left-line {
		margin: 0 0.08rem 0 0.04rem;
		width: 0.12rem;
		height: 0.6rem;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			position: absolute;
		}

		img:first-child {
			width: 100%;
			z-index: 2;
		}

		img:last-child {
			height: 100%;
			z-index: 1;
		}
	}

	.year-box {
		width: 100%;
		height: 0.16rem;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.12rem;

		span {
			margin: 0 0.16rem;
		}

		.line1 {
			width: 40%;
			height: 0.02rem;
			background: linear-gradient(270deg, rgba(225, 225, 225, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
		}

		.line2 {
			width: 40%;
			height: 0.02rem;
			background: linear-gradient(63deg, rgba(225, 225, 225, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
		}
	}
	.listItem:hover{
		background-color: #09fafa20;
	}
	.el-tooltip__popper{
		max-width: 3rem;
	}
</style>
