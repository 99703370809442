<!-- 弹窗组件 -->
<template>
    <div class="win-pop" :class="option.type == 'left' ? 'left' : option.type == 'right' ? 'right' : 'center'" :style="winpopStyle">
        <div class="title">
            <div></div>
            <span>{{ option.name }}</span>
            <i @click="clearPop" class="el-icon-circle-close"></i>
        </div>
        <div style="width: 100%;height: 100%;overflow: hidden;">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        option: Object
    },
    data() {
        return {

        }
    },
    computed: {
        winpopStyle() {
            let obj = {}
            obj.width = this.option.width / 100 + 'rem'
            obj.height = this.option.height / 100 + 'rem'
            return obj
        }
    },
    methods: {
        clearPop: function () {
            this.$emit('clearPop')
        }
    }

}
</script>

<style lang="scss" scoped>
.left {
    left: calc(100% + .16rem);
}

.right {
    right: calc(100% + .16rem);
}

.center {
    position: absolute;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.win-pop {
    // background-color: #dddddd;
    background: linear-gradient(180deg,
				rgba(2, 28, 51, 0.6) 0%,
				rgba(0, 255, 255, 0.6) 51%,
				rgba(2, 28, 51, 0.6) 100%);
                backdrop-filter: blur(0.02rem);
    position: absolute;
    color: #fff;

    // background-color: rgba(0, 0, 0, 0.6);
    // box-shadow: 0px 0px 16px 0px #1fd7fc8c inset;
    // border-radius: .1rem;
    border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
    border: solid .01rem #1fd7fcd3;
    padding: .16rem .22rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .title {
        width: 100%;
        font-size: .16rem;
        // color: #fff;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
	    line-height: 0.3rem;
		color: rgba(255, 255, 255, 0.9);
        border-bottom: 0.01rem solid;
		border-image: linear-gradient(to right,
					rgba(0, 255, 255, 1),
					rgba(24, 142, 245, 0.1)) 1 1;
        div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: .02rem solid rgba(225, 225, 225, 0.3);
            z-index: -1;
            box-sizing: border-box;
        }

        span {
            display: inline-block;
            overflow: hidden;
            box-sizing: border-box;
            padding: 0 .16rem .08rem .16rem;
            border-bottom: .04rem solid #1fd7fc;
        }

        .el-icon-circle-close {
            font-size: .20rem;
            padding: 0 .1rem .1rem 0;
            cursor: pointer;
            color: #fff;
        }

        .el-icon-circle-close:hover {
            transform: scale(1.1);
        }
    }
}
</style>
