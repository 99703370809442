import axios from "../axios/index"
import route from "../router/index"
// 地图基本信息
export const getBasic = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/guide/basic',data)
}
// 村庄变迁
export const getChange = (data) => {
    data.tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/guide/change',data)
}
// 领导关怀-村庄荣誉列表
export const getIconList = (data) => {
	if(data.county){  //获取保康县数据
		data.tenantId = JSON.parse(sessionStorage.getItem('token')).tenant.id
	}else{  //获取村数据
		data.tenantId = localStorage.getItem('tenantId')
	}
    return axios.get('/onemap/portal/guide/iconList',data)
}
// 特殊资产-产业发展列表
export const getInformationList = (data) => {
    data.tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/guide/informationList',data)
}
// 特殊资源-产业发展详情
export const getInformation = (data) =>{
    return axios.get('/onemap/portal/guide/information/'+data)
}
// 视频介绍列表
export const getVideoList = (data) => {
    data.tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/guide/videoList',data)
}
// VR列表720云
export const getVrList = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/guide/vr',data)
}
// 村庄概况
export const getOverView = (id) => {
    let tenantId = localStorage.getItem('tenantId')
    let webState = localStorage.getItem('webState')
    if(route.history.current.name == 'county'){
        tenantId = 'a8e89e5182544007bcdad8bcc1076710'
    }
    // console.log('99---',this.$router)
    // if(webState == 'town') {
    //     tenantId = localStorage.getItem('tenantIdVown')
    // }
    let data = {
        tenantId:id?id:tenantId
    }
    return axios.get('/onemap/portal/guide/overview',data)
}
// 空间规划
export const getChangeK = (data) => {
    data.tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/guide/planning',data)
}
export const getTownData = () => {
    let zhenTenantId = localStorage.getItem('zhenTenantId')
    return axios.get('/onemap/portal/context/diagramList?pageNum=1&pageSize=10&tenantId='+zhenTenantId+'&environmentalType=1&moduleType=3')
}